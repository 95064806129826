import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ziraat1 from "../../assets/img/ziraat1.png"
import ziraat2 from "../../assets/img/ziraat-3.png"

export default function ZiraatKatilimProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Ziraat Katılım Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={ziraat1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("ziraat-katilim")}
            <br />
            <span>{t("project-sector")}</span>: {t("finans")}
            <br />
            <span>{t("project-subject")}</span>: {t("logonun-yorumu")}
            <br />
            <span>{t("project-artwork")}</span>: {t("allah-cc-tekrari")}
          </div>
          <img src={ziraat2} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
